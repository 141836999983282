.mainUpdates {
  text-align: center;
  margin-top: 3vh;
  /* height: 1.5em; */
}

.mainUpdates a {
  color: #002366;
  text-decoration: none;
  word-break: keep-all;
}

.mainUpdates a:hover {
  font-weight: 500;
}

@media screen and (max-width: 993px) {
  .mainUpdates {
    text-align: center;
    /* margin-top: 1.5rem; */
    /* height: 1.5em; */
  }
}
