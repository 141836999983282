.mainBackground {
  background-color: white;
}

.mainContent {
  margin: auto;
  height: 100vh;
  padding: 32vh 5vh 0 5vh;
  display: flex;
  flex-flow: column nowrap;
}

#mainLogoImg {
  width: 25vw;
  min-width: 200px;
  height: 4rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mainDatasearch {
  padding-top: 1.5em;
  width: 100%;
  max-width: 400px;
}

.serviceLink {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 2rem;
  z-index: 100;
}

.serviceLink a {
  color: #002366;
  text-decoration: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.serviceLink :hover {
  font-weight: 600;
}

.serviceLinkText {
  color: '#002366';
}

.onlyForPadding {
  min-height: 64px;
  height: 64px;
  width: 100%;
  clear: both;
  /* background: #efefef; */
}

@media screen and (max-width: 993px) {
  #mainLogoImg {
    display: none;
  }
  .onlyForPadding {
    min-height: 40px;
    height: 40px;
    width: 100%;
    clear: both;
  }
  .mainContent {
    /* min-height: calc(100vh - 76px); */
    min-height: 100vh;
    margin-top: 35%;
    width: 85%;
    margin-bottom: 0;
    padding: 0;
    height: auto;
  }
  .serviceLink {
    padding: 0;
    position: sticky;
    bottom: 0;
    background: white;
    opacity: 0.75;
    margin-bottom: 1rem;
  }
  .serviceLink a div {
    margin-bottom: -0.6rem;
  }
}
