.serviceSection {
  display: flex;
  flex-direction: column;
  background-color: #efefef;
}

.serviceWarp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem 3rem 2rem;
}

.serviceCard {
  width: 600px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 3rem;
}

.searchLink {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  z-index: 1000;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.searchLink a {
  color: #002366;
  text-decoration: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.searchLink :hover {
  font-weight: 600;
}

.titleWarp {
  background-color: #f8f9fa;
  padding: 1.5rem 2rem;
}

.serviceHeadline {
  height: 2rem;
  width: 90%;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: -0.0625px;
  color: #000000;
  font-weight: 600;
}

.serviceDescription {
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.046875px;
  color: #363636;
}

.imgWarp {
  padding: 30px 0;
  text-align: center;
}

.serviceImg {
  object-fit: fill;
  width: 90%;
  height: auto;
}

@media screen and (max-width: 993px) {
  .serviceWarp {
    margin: 0 1.5rem 1.5rem 1.5rem;
  }

  .serviceCard {
    width: 100%;
  }

  .serviceCard:first-child {
    margin-top: 0.4rem;
  }
  .serviceImg {
    width: 90%;
    object-fit: cover;
  }
  .searchLink {
    padding: 0;
    position: sticky;
    top: 40px;
    background: white;
    opacity: 0.75;
  }
  .searchLink a div {
    margin-top: -0.6rem;
  }
}
