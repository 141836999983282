.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  height: 65px;
  border-bottom: 0.5px solid #d1d1d1;
  background-color: white;
  z-index: 1030;
}

.logo {
  flex: 0.8;
}

.navbar {
  display: flex;
  display: -ms-flexbox;
  justify-items: center;
  align-self: center;
}

.navbar a {
  text-decoration: none;
}

.searchbar {
  flex: 1;
  align-self: center;
}

.emptySearchbar {
  flex: 0.5;
}

.logoImg {
  width: 11rem;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  height: 55px;
  cursor: pointer;
}

.aiAtag {
  display: flex;
  flex-flow: row nowrap;
  justify-items: center;
  align-self: center;
}

.ailink {
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
  color: black;
  padding-right: 0.2rem;
}

.ailink :hover {
  text-decoration: none;
  font-weight: 600;
}
.newTag {
  position: relative;
  bottom: 10px;
  align-self: center;
  font-size: 12px;
  color: rgb(229, 25, 42);
  font-weight: 600;
  padding-right: 0.5rem;
}

.betaTag {
  vertical-align: super;
  font-size: 12px;
  color: rgb(229, 25, 42);
  font-weight: 600;
}

.navlink {
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
  color: black;
  padding-top: 5px;
  padding-right: 1.3rem;
}

.navlink:hover {
  text-decoration: none;
  font-weight: 600;
}

.sign,
.signup,
.login {
  border-radius: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.2rem 0.6rem 0.3rem 0.6rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  margin-right: 1.4rem;
  text-align: center;
}

.sign,
.login {
  color: #002366;
  border: 1px solid #002366;
}

.signup {
  color: white;
  background-color: #002366;
}

.sign:hover,
.signup:hover {
  text-decoration: none;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .login {
    display: none;
  }
}

@media screen and (max-width: 993px) {
  .header {
    display: block;
    height: 40px;
    text-align: center;
  }

  .navbar {
    display: none;
  }

  .logo {
    text-align: center;
  }

  .logoImg {
    width: 120px;
    /* margin-left: 0; */
    margin-top: 10px;
    height: 25px;
    cursor: pointer;
  }

  .searchbar {
    display: none;
  }
}
