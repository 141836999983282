.searchbarContanier {
  position: relative;
  margin-top: 3rem;
  text-align: center;
}

@media screen and (max-width: 993px) {
  .searchbarContanier {
    margin-top: 1rem;
  }
}

.searchForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.searchbar {
  height: 2.7rem;
  font-size: 1rem;
  border: 0.5px solid #ccc;
  border-radius: 0;
  box-sizing: border-box;
  padding-left: 1rem;
  width: 30rem;
  background-color: white;
}

.searchbarBtn {
  background-color: #002366;
  border: 0.5px solid #002366;
  height: 2.7rem;
  width: 2.7rem;
  font-size: 1rem;
  color: white;
  padding: 5px !important;
  vertical-align: middle;
}

.searchImg {
  width: 27px;
  height: 27px;
  padding-bottom: 1px;
}

.techniquesListOpen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

@media screen and (max-width: 993px) {
  .searchbar {
    width: 90%;
  }
}
