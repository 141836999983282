.postCards {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  max-width: 1100px;
  margin-top: 6rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.postCard {
  flex: 1 1 0%;
  border: 1px solid #a9a9a9;
  display: flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.375rem;
  flex-flow: column nowrap;
  padding: 1rem;
  min-height: 12rem;
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.title a {
  color: #002366;
  text-decoration: none;
}

.contents {
  flex: 1;
  font-size: 0.9rem;
}

.bottom {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 0.4rem;
}

.date {
  font-size: 0.8rem;
  color: #bbbbbb;
}

.divider {
  font-size: 0.8rem;
  color: #bbbbbb;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.author {
  font-size: 0.8rem;
  flex: 1;
  color: #bbbbbb;
}

.more {
  font-size: 0.8rem;
  /* color: rgba(59, 130, 246); */
  color: #002366;
}

@media screen and (max-width: 993px) {
  .postCards {
    padding-left: 0rem;
    padding-right: 0rem;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    flex-flow: column nowrap;
    margin-top: 35%;
    max-width: 100%;
    min-height: 10rem;
  }
  .postCard {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .contents {
    font-size: 0.8rem;
  }
}
