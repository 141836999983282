.footer {
  padding: 2rem 0;
  background: #f8f9fa;
  overflow: hidden;
  position: relative;
  bottom: 0;
  height: auto;
  line-height: 165%;
}

.footerContents {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.footerLeft {
  min-width: 352px;
}

.footercenter {
  border-right: 0.5px solid #424242;
  margin: 0 2rem;
}

.footerRight {
  min-width: 107px;
}

.corporateName {
  text-align: left;
  color: #424242;
  font-size: 14px;
  font-weight: 400;
}

.corporateInfo,
.corporateNumber {
  text-align: left;
  color: #424242;
  font-size: 14px;
  font-weight: 300;
}

.corporateContact {
  text-align: left;
  color: #424242;
  font-size: 14px;
  font-weight: 300;
}

.privacyPolicy,
.termsOfService,
.update,
.searchArea {
  text-align: left;
  color: #424242;
  font-size: 14px;
  font-weight: 400;
}

.privacyPolicy a,
.termsOfService a,
.update a,
.searchArea a {
  text-decoration: none;
}

.corporatePrivacyPolicy,
.corporateTermsOfService,
.updatePage,
.search {
  color: #424242 !important;
}

.corporatePrivacyPolicy:hover,
.corporateTermsOfService:hover,
.updatePage:hover,
.search:hover {
  font-weight: 500;
}

@media print {
  .footerArea {
    display: none;
  }
}

@media screen and (max-width: 993px) {
  .footer {
    /* height: 171px; */
    padding: 1.7rem 0;
    text-align: center;
  }

  .footer a {
    text-decoration: none;
  }

  .mobileRow {
    /* padding-left: 20px;
    padding-right: 20px; */
    margin: 0;
  }

  .mobileCoporateFooter {
    padding: 1.7rem 0 0 0;
    font-size: 13px;
    font-weight: 300;
    line-height: 165%;
  }

  .corporatePrivacyPolicy,
  .corporateTermsOfService,
  .updatePage,
  .aboutUs,
  .search {
    color: #424242 !important;
    font-size: 13px !important;
    font-weight: 400;
  }

  .mobileCoporateCopyright {
    font-size: 13px;
    font-weight: 400;
    padding: 1.7rem 0 0 0;
  }
}
