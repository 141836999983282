.menuWarp {
  display: none;
}
/* @media */
@media screen and (max-width: 993px) {
  .menuWarp {
    display: block;
  }

  .menu {
    position: fixed;
    background-color: white;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2000;
  }

  .user {
    position: relative;
    display: flex;
    top: 50px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    height: 55px;
    border-top: 0.5px solid #c4c4c4;
  }

  .user a,
  .standard a {
    text-decoration: none;
  }

  .login {
    width: 50vw;
    position: relative;
    line-height: 55px;
    background-color: white;
    color: #424242;
  }

  .signup {
    width: 50vw;
    position: relative;
    line-height: 55px;
    background-color: white;
    color: #424242;
    border-left: 0.5px solid #c4c4c4;
  }

  .personal {
    position: relative;
    top: 50px;
    font-size: 18px;
    line-height: 40px;
    border-top: 0.5px solid #c4c4c4;
    padding: 1rem 0 1rem 0;
  }

  .standard {
    position: relative;
    top: 50px;
    font-size: 18px;
    line-height: 40px;
    font-weight: 500;
    border-top: 0.5px solid #c4c4c4;
    padding: 1rem 0 1rem 0;
  }

  .link {
    padding: 0.5rem 0 0.5rem 2rem;
    color: #424242;
    text-align: left;
  }

  .aiAtag {
    display: flex;
    flex-flow: row nowrap;
    justify-items: center;
    align-self: center;
    padding: 0.5rem 0 0.5rem 2rem;
    text-align: left;
  }

  .ailink {
    text-align: center;
    cursor: pointer;
    color: #424242;
    padding-right: 0.2rem;
  }

  .newTag {
    position: relative;
    bottom: 10px;
    align-self: center;
    font-size: 12px;
    color: rgb(229, 25, 42);
    font-weight: 600;
    padding-right: 0.5rem;
  }

  .buttonWarp {
    text-align: left;
  }

  .menuButton {
    border: none;
    background-color: white;
    padding: 0.5rem 0 0.5rem 2rem;
    color: #424242;
    font-weight: 500;
  }
}
