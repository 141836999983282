.moblie {
  display: none;
}

.hamburger {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 20px;
  border: none;
  cursor: pointer;
  z-index: 9000;
}

.hamburger ::focus {
  outline: none;
}

.burger {
  width: 20px;
  height: 2px;
  border-radius: 15px;
  position: relative;
  transform-origin: 0.5px;
  background-color: #4c4c4c;
}

@media screen and (max-width: 993px) {
  .moblie {
    display: block;
  }
}
